<template>
  <div class="shareLine"></div>
</template>

<script>
export default {
  name: 'shareLine',
  props: {
    data: Object
  },
  data() {
    return {
      dataSource: this.data,
      myChart: undefined
    }
  },
  watch: {
    data: {
      deep: true, // 深度监听
      handler: function (newVal) {
        this.dataSource = newVal
        this.setChart()
      }
    }
  },
  methods: {
    setChart() {
      let option = {
        legend: {
          textStyle: {
            color: '#f5f5f5'
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: false
        },
        dataset: this.dataSource.dataset,
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#f5f5f5'
            }
          }
        },
        yAxis: {
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: '#f5f5f5'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#57617B'
            }
          }
        },
        grid: { top: '55%' },
        series: [
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            itemStyle: {
              normal: {
                color: 'rgb(0,136,212)',
                borderColor: 'rgba(0,136,212,0.2)',
                borderWidth: 12
              }
            },
            emphasis: { focus: 'series' }
          },
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            itemStyle: {
              normal: {
                color: 'rgb(137,189,27)',
                borderColor: 'rgba(137,189,2,0.27)',
                borderWidth: 12
              }
            }
          },
          {
            type: 'pie',
            id: 'pie',
            radius: '30%',
            center: ['50%', '25%'],
            emphasis: {
              focus: 'self'
            },
            label: {
              formatter: '{b}: {@c} ({d}%)'
            },
            encode: {
              itemName: 'product',
              value: this.dataSource.pieParam,
              tooltip: this.dataSource.pieParam
            },
            // 设置饼图的颜色

            color: ['rgb(0,136,212)', 'rgb(137,189,27)'] // 颜色数组
          }
        ]
      }
      this.myChart = this.$echarts(this.$el)
      const that = this
      this.myChart.on('updateAxisPointer', function (event) {
        const xAxisInfo = event.axesInfo[0]
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1
          that.myChart.setOption({
            series: {
              id: 'pie',
              label: {
                formatter: '{b}: {@[' + dimension + ']} ({d}%)'
              },
              encode: {
                value: dimension,
                tooltip: dimension
              }
            }
          })
        }
      })
      this.myChart.clear()
      this.myChart.resize()
      this.myChart.setOption(option)
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style scoped>
.shareLine {
  height: 100%;
  width: 100%;
  padding: 0 20px;
}
</style>
